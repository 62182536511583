<template>
  <el-dialog :visible.sync="visible" title="图纸升版" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners" width="90%">
    <el-card>
      <el-upload ref="upload" accept=".pdf,.dwg" action="" :file-list="fileList" :show-file-list="false"
          name="files" :auto-upload="false" :on-remove="handleRemove" :on-change="handleChange">
        <el-button slot="trigger" size="small" type="primary" @click="resetFileCount">选取文件</el-button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <el-button size="small" type="success" @click="submitUpload">上传</el-button>
        <br><br>
        <span>{{paperName}}，您已选择 <i style="color: red; font-weight: bold">{{fileList.length}}</i> 个文件，</span>
        <span>将上传文件的第</span><el-input-number v-model="paperNum" step-strictly size="mini" controls-position="right" :min="1" style="width: 90px"></el-input-number><span>页用于图纸升版。</span>
      </el-upload>
      <br>
      <el-steps direction="vertical" :active="0">
        <el-step status="process" title="支持 pdf 和 dwg 格式的图纸文件。"></el-step>
        <el-step status="process" title="如果您未选择图纸目录，系统将尝试智能匹配对应的目录。"></el-step>
      </el-steps>
      <el-row :gutter="20" v-if="paperIds.length!=0" style="margin-top: 20px">
        <el-col :span="6">
          <div>
            <el-button type="primary" icon="el-icon-star-off" @click="release">发布</el-button>
          </div>
        </el-col>
        <el-col :span="6" :offset="12">
          <div style="float: right;">
            <!-- 这个按钮改成删除图纸 -->
            <el-button type="danger" icon="el-icon-star-off" @click="del">删除</el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </el-dialog>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      visible: false,
      prjId: '',
      statusId: '1', // 附件的状态（0：临时附件，1：永久附件）
      fileList: [],
      oldFileList: [],
      fileCount: 0,
      paperIds: [], // 图纸ID
      paperNum: 1, // 若图纸存在多页，指定图纸第几页作为图纸升版依据
      paperId: '', // 当前选中需要升版的图纸ID
      paperName: '', // 当前选中需要升版的图纸名称
      paperPreviousVersion: '' // 当前选中需要升版的图纸版本
    }
  },
  created: function () {
    //this.init()
  },
  methods: {
    init () {
      this.visible = true
      if (this.paperId && this.paperName) {
        this.paperName = '要升版的图纸：[' + this.paperName + ']'
      } else {
        this.paperName = '未选择要升版的图纸'
      }
    },
    // 选择文件
    resetFileCount () {
      if (this.prjId) {
        this.fileCount = 0
        this.paperIds = []
      } else {
        this.$message({
          message: '请先选择项目！',
          type: 'error',
          duration: 4000
        })
        this.$refs.upload.abort()
        this.visible = false
      }
    },
    handleChange (file, fileList) { // 文件状态改变钩子
      // 第一次on-change获取fileList
      if (this.fileCount === 0) {
        // 多个文件fileList会变化
        this.oldFileList = Object.assign(this.fileList)
      }
      // 当前选择的文件是否已经存在，避免重复选择
      let isTrue = this.oldFileList.some((f) => {
        return f.name === file.name
      })

      if (isTrue) {
        this.$message.warning('请勿重复上传！')
        fileList.pop()
        return
      }
      this.fileList = fileList
      this.fileCount++
    },
    // 删除上传文件
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    // 提交点击
    submitUpload () {
      if (this.fileList.length === 0) {
        this.$message.warning('请先选择要上传的文件！')
        return
      }
      let formData = new FormData()
      // 仅取第一个上传文件作为图纸升版的依据
      formData.append('file', this.fileList[0].raw)

      let promptTitle = '请填写新的版本号'
      if (this.paperPreviousVersion) {
        promptTitle += "（历史版本："+this.paperPreviousVersion+")"
      }
      // 到后台检索该图纸最新的版本号
      this.$prompt(promptTitle, '', {
        confirmButtonText: '确定',
        closeOnClickModal: false,
        showCancelButton: false,
        inputValidator: (val) => {
          if (!val || new RegExp('^[ ]+$').test(val)) { // 验证空格或者全部都是空格
            return '请您填写图纸的版本号'
          }
        }
      }).then(({ value }) => {
        const loading = this.$loading({
          lock: true,
          text: '正在上传并进行格式转换，请耐心等待...',
          customClass: 'create-isLoading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let version = value.trim()

        this.$http.post(`/mps/paper/paperUpgrade?_t=${new Date()}&statusId=${this.statusId}&version=${version}&prjId=${this.prjId}&paperId=${this.paperId}&paperNum=${this.paperNum}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(({ data: res }) => {
            if (res.code !== 0) {
              return this.$message.error({
                message: res.msg,
                duration: 5000
              })
            } else {
              this.paperIds = res.data
              this.$confirm('上传成功，是否发布图纸？', '确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                type: 'warning'
              }).then(() => {
                // 发布图纸
                this.release()
              }).catch(() => {
                // do nothing
              })
            }
          }).catch(() => {
          this.$message({
            message: '图纸上传过程中出现错误，请稍后重试！',
            type: 'error',
            duration: 5000
          })
        }).finally(() => {
          loading.close()
        })
      }).catch(() => {
        // do nothing
      })
    },
    // 确认发布
    release () {
      const loading = this.$loading({
        lock: true,
        text: '正在发布图纸，请稍等...',
        customClass: 'create-isLoading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      const data = this.paperIds.map(id => {
        return { id: id, prjId: this.prjId }
      })
      this.$http.post(`/mps/paper/release?_t=${new Date()}`, data)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          } else {
            this.$message({
              message: '图纸发布成功。',
              type: 'success',
              duration: 2000,
              onClose: () => {
                this.visible = false
                this.$emit('refreshDataList')
              }
            })
          }
        }).catch(() => {
          this.$message({
            message: '发布图纸过程中出现错误，请稍后重试！',
            type: 'error',
            duration: 5000
          })
        }).finally(() => {
          loading.close()
        })
    },
    // 不发布，直接删除
    del () {
      this.$confirm('您确定要删除刚上传的图纸?', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '正在删除，请稍等...',
          spinner: 'el-icon-loading',
          customClass: 'create-isLoading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        if (this.paperIds && this.paperIds.length > 0) {
          this.$http.delete('/mps/paper', { 'data': this.paperIds })
            .then(({ data: res }) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg)
              } else {
                this.visible = false
                this.$emit('refreshDataList')
              }
            }).catch(() => {
              this.$message({
                message: '图纸删除过程中出现错误，请稍后重试！',
                type: 'error',
                duration: 5000
              })
            }).finally(() => {
              loading.close()
            })
        } else {
          this.visible = false
          this.$emit('refreshDataList')
        }
      }).catch(() => {
        // do nothing
      })
    }
  }
}
</script>
<style lang="scss">
  .create-isLoading {
    .el-loading-spinner {
      top: 50%;
      left: 50%;
      margin-left: -55px;
      background: rgba(0, 0, 0, 0.7);
      padding: 20px;
      border-radius: 4px;
      width: auto;
      text-align: center;
      position: absolute;

      i {
        color: #eee;
      }

      .el-loading-text {
        color: #eee;
      }
    }
  }
</style>
