<template>
  <el-dialog :visible.sync="visible" title="权限分配" width="80%" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-row :gutter="10">
      <el-col :span="8">
        <div class="title-container">
          <span>项目</span>
        </div>
        <div class="tree-container" style="width: 100%; overflow: auto;">
          <el-tree
              v-loading="projectTreeLoading"
              :data="projectTreeData"
              node-key="id"
              :props="{children: 'children', label: 'shortName'}"
              ref="projectTree"
              @node-click="projectTreeNodeClick"
              @check-change="projectTreeCheckChange"
              show-checkbox
              :highlight-current="true"
          >
          </el-tree>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="title-container">
          <span>标段</span>
        </div>
        <div class="tree-container" style="width: 100%; overflow: auto;">
          <el-tree
              v-loading="contractTreeLoading"
              :empty-text="contractEmptyText"
              :data="contractTreeData"
              node-key="id"
              :props="{children: 'children', label: 'shortName'}"
              ref="contractTree"
              @node-click="contractTreeNodeClick"
              @check-change="contractTreeCheckChange"
              show-checkbox
              :highlight-current="true"
          >
          </el-tree>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="title-container">
          <span>工区</span>
        </div>
        <div class="tree-container" style="width: 100%; overflow: auto;">
          <el-tree
              v-loading="subcontractorTreeLoading"
              :empty-text="subcontractorEmptyText"
              :data="subcontractorTreeData"
              node-key="id"
              :props="{children: 'children', label: 'name'}"
              ref="subcontractorTree"
              @check-change="subcontractorTreeCheckChange"
              show-checkbox
              :highlight-current="true"
          >
          </el-tree>
        </div>
      </el-col>
    </el-row>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  data() {
    return {
      visible: false,
      contractEmptyText: '选择项目',
      subcontractorEmptyText: '选择合同标段',
      userId: '',
      projectTreeData: [],
      contractTreeData: [],
      subcontractorTreeData: [],
      projectTreeLoading: false,
      contractTreeLoading: false,
      subcontractorTreeLoading: false,
      projectNewList: [],
      projectRemoveList: [],
      contractNewList: [],
      contractRemoveList: [],
      subcontractorNewList: [],
      subcontractorRemoveList: [],
    }
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        this.getProjectTreeData()
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      let data = {
        userId: this.userId,
        projectNewList: this.projectNewList,
        projectRemoveList: this.projectRemoveList,
        contractNewList: this.contractNewList,
        contractRemoveList: this.contractRemoveList,
        subcontractorNewList: this.subcontractorNewList,
        subcontractorRemoveList: this.subcontractorRemoveList,
      }
      this.$http.post('/mps/project/authorityUpdate',  data).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.$message({
          message: this.$t('prompt.success'),
          type: 'success',
          duration: 1500,
          onClose: () => {
            this.visible = false
            this.$emit('refreshDataList', this.row)
          }
        })
      }).catch(() => {})
    }, 1000, { 'leading': true, 'trailing': false }),
    getProjectTreeData() {
      this.projectTreeLoading = true
      this.$http.get('/mps/project/openapi/briefsForMe').then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.projectTreeData = res.data
        this.$http.get('/mps/project/openapi/briefsForUser', {
            params: {
              userId: this.userId
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          let keys = res.data.map(obj => {
            return obj.id
          })
          if (this.projectNewList && this.projectNewList.length > 0) {
            for (const k of this.projectNewList) {
              if (!keys.includes(k)) {
                keys.push(k)
              }
            }
          }
          if (this.projectRemoveList && this.projectRemoveList.length > 0) {
            for (const k of this.projectRemoveList) {
              if (keys.indexOf(k) > -1) {
                keys.splice(keys.indexOf(k), 1)
              }
            }
          }
          
          this.$refs.projectTree.setCheckedKeys(keys, true)
          this.$nextTick(() => {
            this.projectTreeLoading = false
            // 多选框回显时会触发checkChange事件，所以需要将事件的影响还原
            //this.projectNewList = []
          })
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    projectTreeNodeClick(data) {
      this.getContractTreeData(data.id)
      this.subcontractorTreeData = []
      this.subcontractorEmptyText = '选择一个合同标段'
    },
    projectTreeCheckChange(data, checked, childrenChecked) {
      let projectId = data.id
      this.$refs.projectTree.setCurrentKey(projectId)
      this.projectTreeNodeClick(data)
      // 1.  如果勾选
      //    1.1 如果减量列表里有，则将其从减量列表删除
      //    1.2 否则如果增量列表里没有，则将其添加到增量列表
      // 2.  如果取消勾选
      //    2.1 如果增量列表里有，则将其从增量列表删除
      //    2.2 否则如果减量列表里没有，则将其添加到减量列表
      if (checked) {
        if (this.projectRemoveList.indexOf(projectId) > -1) {
          this.projectRemoveList.splice(this.projectRemoveList.indexOf(projectId), 1)
        }
        if (!this.projectNewList.includes(projectId)) {
          this.projectNewList.push(projectId)
        }
      } else {
        if (this.projectNewList.indexOf(projectId) > -1) {
          this.projectNewList.splice(this.projectNewList.indexOf(projectId), 1)
        }
        if (!this.projectRemoveList.includes(projectId)) {
          this.projectRemoveList.push(projectId)
        }
      }
    },
    getContractTreeData(prjId) {
      this.contractTreeLoading = true
      this.$http.get('/mps/contract/openapi/briefs', {
          params: {
            prjId: prjId,
          }
        }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.contractEmptyText = '无数据'
        this.contractTreeData = res.data
        this.$http.get(
          '/mps/contract/openapi/briefsForUser',
          {
            params: {
              prjId: prjId,
              userId: this.userId
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          let keys = res.data.map(obj => {
            return obj.id
          })
          if (this.contractNewList && this.contractNewList.length > 0) {
            for (const k of this.contractNewList) {
              if (!keys.includes(k)) {
                keys.push(k)
              }
            }
          }
          if (this.contractRemoveList && this.contractRemoveList.length > 0) {
            for (const k of this.contractRemoveList) {
              if (keys.indexOf(k) > -1) {
                keys.splice(keys.indexOf(k), 1)
              }
            }
          }
          this.$refs.contractTree.setCheckedKeys(keys, true)
          this.$nextTick(() => {
            this.contractTreeLoading = false
            // 多选框回显时会触发checkChange事件，所以需要将事件的影响还原
            //this.contractNewList = []
          })
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    contractTreeNodeClick(data) {
      this.getSubcontractorTreeData(data.id)
    },
    contractTreeCheckChange(data, checked, childrenChecked) {
      let contractId = data.id
      this.$refs.contractTree.setCurrentKey(contractId)
      this.contractTreeNodeClick(data)
      // 1.  如果勾选
      //    1.1 如果减量列表里有，则将其从减量列表删除
      //    1.2 否则如果增量列表里没有，则将其添加到增量列表
      // 2.  如果取消勾选
      //    2.1 如果增量列表里有，则将其从增量列表删除
      //    2.2 否则如果减量列表里没有，则将其添加到减量列表
      if (checked) {
        if (this.contractRemoveList.indexOf(contractId) > -1) {
          this.contractRemoveList.splice(this.contractRemoveList.indexOf(contractId), 1)
        }
        if (!this.contractNewList.includes(contractId)) {
          this.contractNewList.push(contractId)
        }
      } else {
        if (this.contractNewList.indexOf(contractId) > -1) {
          this.contractNewList.splice(this.contractNewList.indexOf(contractId), 1)
        }
        if (!this.contractRemoveList.includes(contractId)) {
          this.contractRemoveList.push(contractId)
        }
      }
    },
    getSubcontractorTreeData(contractId) {
      this.subcontractorTreeLoading = true
      this.$http.get('/mps/subcontractor/openapi/briefs', {
          params: {
            contractId: contractId,
          }
        }
      ).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.subcontractorEmptyText = '无数据'
        this.subcontractorTreeData = res.data
        this.$http.get('/mps/subcontractor/openapi/briefsForUser', {
            params: {
              contractId: contractId,
              userId: this.userId
            }
          }
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          let keys = res.data.map(obj => {
            return obj.id
          })
          if (this.subcontractorNewList && this.subcontractorNewList.length > 0) {
            for (const k of this.subcontractorNewList) {
              if (!keys.includes(k)) {
                keys.push(k)
              }
            }
          }
          if (this.subcontractorRemoveList && this.subcontractorRemoveList.length > 0) {
            for (const k of this.subcontractorRemoveList) {
              if (keys.indexOf(k) > -1) {
                keys.splice(keys.indexOf(k), 1)
              }
            }
          }
          this.$refs.subcontractorTree.setCheckedKeys(keys, true)
          this.$nextTick(() => {
            this.subcontractorTreeLoading = false
            // 多选框回显时会触发checkChange事件，所以需要将事件的影响还原
            //this.subcontractorNewList = []
          })
        }).catch(() => {
          return this.$message.error('出错了')
        })
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
    subcontractorTreeCheckChange(data, checked, childrenChecked) {
      let subcontractorId = data.id
      // 1.  如果勾选
      //    1.1 如果减量列表里有，则将其从减量列表删除
      //    1.2 否则如果增量列表里没有，则将其添加到增量列表
      // 2.  如果取消勾选
      //    2.1 如果增量列表里有，则将其从增量列表删除
      //    2.2 否则如果减量列表里没有，则将其添加到减量列表
      if (checked) {
        if (this.subcontractorRemoveList.indexOf(subcontractorId) > -1) {
          this.subcontractorRemoveList.splice(this.subcontractorRemoveList.indexOf(subcontractorId), 1)
        }
        if (!this.subcontractorNewList.includes(subcontractorId)) {
          this.subcontractorNewList.push(subcontractorId)
        }
      } else {
        if (this.subcontractorNewList.indexOf(subcontractorId) > -1) {
          this.subcontractorNewList.splice(this.subcontractorNewList.indexOf(subcontractorId), 1)
        }
        if (!this.subcontractorRemoveList.includes(subcontractorId)) {
          this.subcontractorRemoveList.push(subcontractorId)
        }
      }
    },
  }

}
</script>

<style scoped>
.title-container {
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
}
.tree-container {
  height: 400px;
  border: 1px #E5E5E5 solid;
  border-radius: 10px;
  padding: 10px;
}
</style>