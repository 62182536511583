<template>
  <el-dialog :visible.sync="visible" title="完善信息" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners" :append-to-body="true">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="5">
        <el-col :span="8">
          <el-form-item label="版本号" prop="version">
            <el-input v-model="dataForm.version" placeholder="请填写最新的版本号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="专业" prop="majorId">
            <major-select placeholder="专业选择" v-model="dataForm.majorId" clearable></major-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="专业负责人" prop="majorInCharge">
            <el-input v-model="dataForm.majorInCharge" placeholder="专业负责人"></el-input>
          </el-form-item>
        </el-col>
        
      </el-row>

      <el-row :gutter="5">
        <el-col :span="8">
          <el-form-item label="设计人" prop="designer">
            <el-input v-model="dataForm.designer" placeholder="设计人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="设计负责人" prop="designInCharge">
            <el-input v-model="dataForm.designInCharge" placeholder="设计负责人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所属阶段" prop="stageId">
            <el-radio-group v-model="dataForm.stageId">
              <el-radio :label="1">初步设计</el-radio>
              <el-radio :label="2">施工图</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="5">
        <el-col :span="8">
          <el-form-item label="复核人" prop="reviewer">
            <el-input v-model="dataForm.reviewer" placeholder="复核人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="审核人" prop="verifier">
            <el-input v-model="dataForm.verifier" placeholder="审核人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button type="primary" @click="doSubmitHandle()">确定提交</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  data () {
    return {
      visible: false,
      paperIds: [], // 图纸ID
      dataForm: {
        version: '',
        majorId: '',
        stageId: '',
        designer: '',
        reviewer: '',
        verifier: '',
        designInCharge: '',
        majorInCharge: ''
      }
    }
  },
  computed: {
    dataRule () {
      return {
        version: [
          { required: true, message: this.$t('validate.required'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    init () {
      this.visible = true
    },
    doSubmitHandle () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.visible = false
        this.$emit('uploadAndSubmitForm', this.dataForm)
      })
    },
  }
}
</script>
