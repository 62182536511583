<template>
  <el-dialog top="5vh" width="80%" append-to-body v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? $t('add') : $t('update')" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="80px">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item prop="code" label="清单编码">
            <el-input v-model="dataForm.code" placeholder="清单编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="上级节点" prop="pid">
            <boq-template-parent-select v-model="dataForm.pid" :mainId="dataForm.mainId"></boq-template-parent-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="name" label="名称">
            <el-input v-model="dataForm.name" placeholder="名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="unitName" label="单位">
            <el-input v-model="dataForm.unitName" placeholder="单位"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 2 }"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sortNo" label="排序">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" label="排序号"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import debounce from 'lodash/debounce'
  import BoqTemplateParentSelect from './boqtemplate-parent-select'
  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: '',
          mainId: '',
          pid: '',
          name: '',
          code: '',
          unitName: '',
          remark: '',
          sortNo: 0,
        },
        oldPid:'' // 记录数据原始的上级节点ID
      }
    },
    computed: {
      dataRule () {
        return {
          name: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ],
          code: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ],
          pid: [
            {required: true,message: this.$t('validate.required'),trigger: 'blur'}
          ],
        }
      }
    },
    components:{
      BoqTemplateParentSelect
    },
    methods: {
      init () {
        this.visible = true
        this.$nextTick(() => {
          if (this.dataForm.id) {
            this.getInfo()
          } else {
            // 获取排序序号
            this.getNewSortNo()
          }
        })
      },
      // 获取排序序号
      getNewSortNo() {
        this.$http.get(`/mps/boqTemplate/${this.dataForm.mainId}/${this.dataForm.pid}/newSortNo`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm.sortNo = res.data
        }).catch(() => {
          // do nothing
        })
      },
      // 获取信息
      getInfo () {
        this.$http.get(`/mps/boqTemplate/${this.dataForm.id}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data
          }
          this.oldPid = this.dataForm.pid
        }).catch(() => {
          // do nothing
        })
      },
      // 表单提交
      dataFormSubmitHandle: debounce(function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false
          }
          this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/boqTemplate', this.dataForm).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            if (this.dataForm.id) {
              this.$emit('updateNode', this.dataForm.pid, this.oldPid != this.dataForm.pid)
            } else {
              this.$emit('addNode', this.dataForm.pid)
            }
            this.$message({
              message: this.$t('prompt.success'),
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.visible = false
              }
            })
          }).catch(() => {
            // do nothing
          })
        })
      },1000,{'leading': true, 'trailing': false})
    }
  }
</script>
