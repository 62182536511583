<template>
  <vxe-pulldown ref="xDown" transfer style="width: 100%">
    <template #default>
      <el-tooltip placement="top-start" :content="name" effect="light" :enterable="false" :open-delay="500">
        <vxe-input v-model="name" style="width: 100%;" size="small" :suffix-icon="!readonly?'vxe-icon-search':''" :placeholder="placeholder" @click="showDropdown" readonly></vxe-input>
      </el-tooltip>
    </template>
    <template #dropdown>
      <vxe-table
        max-height="300"
        show-overflow
        :row-config="{isHover: true,isCurrent: true,useKey: true}"
        :column-config="{resizable: true}"
        :tree-config="treeConfig"
        :data="tableData"
        size="mini"
        @cell-click="cellClickEvent"
        :scroll-y="{enabled: false}">
        <vxe-column field="name" title="名称" tree-node>
          <template v-slot="{row}">
            <span>{{ row.code + ' ' + row.name }}</span>
          </template>
        </vxe-column>
      </vxe-table>
    </template>
  </vxe-pulldown>
</template>
<script>
  export default {
    data () {
      return {
        treeConfig: {
          transform: true,
          rowField: 'id',
          parentField: 'pid',
          line: true,
          lazy: true,
          hasChild: 'hasChildren',
          loadMethod: this.loadChildren,
          iconOpen: 'vxe-icon-square-minus',
          iconClose: 'vxe-icon-square-plus'
        },
        tableData: [],
        name: '',
        defaultParentName: '无',
        dataLoading:false
      }
    },
    created: function () {
      this.getInfo()
    },
    props: {
      value: [String,Number],
      placeholder: String,
      readonly: Boolean,
      // 默认只显示节点名称,传入true则显示完整路径，
      showPNames: {
        type: Boolean,
        default: false
      },
      mainId: String, // 主表ID
    },
    model: {
      prop: 'value',
      event: 'change'
    },
    watch: {
      // 数据回显
      value: function (newVal,oldVal) {
        this.getInfo()
      }
    },
    methods: {
      showDropdown () {
        if (!this.readonly) {
          if (!this.tableData || this.tableData.length == 0) {
            this.getDataList()
          }
          this.$refs.xDown.showPanel()
        }
      },
      cellClickEvent ({row,triggerTreeNode}) {
        // 如果是折叠/展开树的操作，就不触发点击事件
        if (!triggerTreeNode) {
          this.showName(row)
          this.$emit('change',row.id)
          this.$refs.xDown.hidePanel()
        }
      },
      getInfo () {
        if (this.value) {
          this.$http.get('/mps/boqTemplate/' + this.value).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.showName(res.data)
          }).catch(() => {
            // do nothing
          })
        } else {
          this.name = this.defaultParentName
        }
      },
      showName (data) {
        if (data) {
          if (this.showPNames) {
            this.name = data.pnames ? (data.pnames + '，' + data.name) : data.name
          } else {
            this.name = data.name
          }
        } else {
          this.name = this.defaultParentName
        }
      },
      loadChildren ({row}) {
        // 异步加载子节点
        return new Promise(resolve => {
          this.$http.get(
            '/mps/boqTemplate/findChildren',
            {
              params: {
                pid: row.id,
                mainId: this.mainId
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            resolve(res.data)
          }).catch(() => {
            // do nothing
          })
        })
      },
      getDataList () {
        if (!this.dataLoading) {
          this.dataLoading = true
          this.$http.get(
            '/mps/boqTemplate/findChildren',
            {
              params: {
                pid: 0,
                mainId: this.mainId
              }
            }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              return this.$message.error(res.msg)
            }
            this.tableData = res.data
          }).catch(() => {
            // do nothing
          }).finally(() => {
            this.dataLoading = false
          })
        }
      }
    },
  }
</script>
<style scoped>
</style>
