<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container :left-width="220">
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <el-input v-model="treeFilterText" placeholder="输入关键字查询"></el-input>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree
            class="left-tree"
            v-loading="treeLoading"
            :data="treeData"
            node-key="id"
            :props="defaultProps"
            ref="tree"
            @node-click="treeNodeClick"
            :highlight-current="true"
            :filter-node-method="filterNode"
          >
          </el-tree>
        </div>
      </template>
	    <template v-slot:right>
        <el-main>
          <div class="mod-sys__user">
            <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
              <el-form-item>
                <el-input v-model="dataForm.realName" placeholder="姓名" style="width: 140px" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="dataForm.username" placeholder="登录账号" style="width: 160px" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="dataForm.mobile" placeholder="手机号码" style="width: 160px" clearable></el-input>
              </el-form-item>
              <!-- <el-form-item>
                <dict-select v-model="dataForm.gender" dict-type="gender" placeholder="性别"></dict-select>
              </el-form-item> -->
              <el-form-item>
                <el-button-group style="margin-top: -3px;">
                  <toolbar-button role="query" @click="getDataList()"></toolbar-button>
                  <toolbar-button role="add" v-if="$hasPermission('sys:user:save')" @click="addOrUpdateHandle()"></toolbar-button>
                  <toolbar-button role="delete" v-if="$hasPermission('sys:user:delete')" @click="deleteHandle()"></toolbar-button>
                  <toolbar-button role="export"  v-if="$hasPermission('sys:user:export')" @click="exportHandle()"></toolbar-button>
                  <el-tooltip content="缓存所有用户的姓名，提高系统运行速度" placement="top" effect="light">
                    <el-button icon="el-icon-refresh-right" v-if="$hasPermission('sys:user:cache')" @click="cacheRealNames()"></el-button>
                  </el-tooltip>
                </el-button-group>
              </el-form-item>
            </el-form>
            <el-table
                v-loading="dataListLoading"
                :data="dataList" border style="width: 100%;"
                highlight-current-row
                @selection-change="dataListSelectionChangeHandle"
                @sort-change="dataListSortChangeHandle"
                :cell-style="{padding: '2px 0'}">
              <el-table-column type="selection" align="center" width="50"></el-table-column>
              <el-table-column prop="realName" label="姓名" sortable="custom" width="130"></el-table-column>
              <el-table-column prop="username" label="登录账号" sortable="custom" width="130"></el-table-column>
              <el-table-column prop="deptName" label="所属部门">
                <template slot-scope="scope">
                  {{ scope.row.pnames ? scope.row.pnames + '/' + scope.row.deptName : scope.row.deptName }}
                </template>
              </el-table-column>
              <el-table-column prop="mobile" label="手机号码" sortable="custom" align="center" width="130"></el-table-column>
              <el-table-column prop="status" label="用户状态" sortable="custom" align="center" width="100">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 0" size="small" type="danger">{{ $t('user.status0') }}</el-tag>
                  <el-tag v-else size="small" type="success">{{ $t('user.status1') }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column :label="$t('handle')" fixed="right" align="center" width="140">
                <template slot-scope="scope">
                  <table-button role="update" v-if="$hasPermission('sys:user:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
                  <table-button role="delete" v-if="$hasPermission('sys:user:delete')" @click="deleteHandle(scope.row.id)"></table-button>
                  <table-button icon="el-icon-setting" tooltip="分配项目、标段等数据范围权限" v-if="$hasPermission('sys:user:update')" @click="openAuthorityHandle(scope.row.id)"></table-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                :current-page="page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="limit"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle">
            </el-pagination>
            <!-- 弹窗, 新增 / 修改 -->
            <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
            <!-- 项目、标段、工区的数据权限分配 -->
            <user-authority v-if="authorityVisible" ref="authority" @close="closeAuthorityHandle"></user-authority>
          </div>
        </el-main>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './user-add-or-update'
import UserAuthority from './user-authority'

export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/sys/user/page',
        getDataListIsPage: true,
        deleteURL: '/sys/user',
        exportURL: '/sys/user/export',
        createdIsNeed: false
      },
      postList: [],
      dataForm: {
        realName: '',
        username: '',
        mobile: '',
        deptRootId: '',
        postId: '',
        gender: ''
      },
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      treeFilterText: '',
      treeLoading: false,
      authorityVisible: false,
    }
  },
  components: {
    AddOrUpdate,
    UserAuthority,
  },
  created () {
    this.getDeptTreeData()
    // this.getPostList()
  },
  watch: {
    treeFilterText (val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    openAuthorityHandle(userId) {
      this.authorityVisible = true;
      this.$nextTick(() => {
        this.$refs.authority.userId = userId
        this.$refs.authority.init()
      })
    },
    closeAuthorityHandle() {
      this.authorityVisible = false;
    },
    getDeptTreeData() {
      this.treeLoading = true
      this.$http.get('/sys/dept/openapi/treeForMe').then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.treeData = res.data
        this.$nextTick(() => {
          const firstNode = document.querySelector('.el-tree-node')
          firstNode.click();
        })
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    getPostList () {
      this.$http.get('/sys/post/list').then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.postList = res.data
      }).catch(() => {})
    },
    treeNodeClick(data, node, ele) {
      this.dataForm.deptRootId = data.id
      this.getDataList()
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    // 将所有用户的ID、REAL_NAME缓存到redis，方便其他微服务根据ID直接获取到姓名
    cacheRealNames () {
      return this.$confirm(`是否要重新缓存全部用户姓名？`).then(() => {
        this.$http.get('/sys/user/cacheRealNames').then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('已重新缓存。')
        }).catch()
      }).catch()
    },
  }
}
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 160px);
  }
</style>